import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ActionDetails from "../components/ActionDetails"
import { ActionType, Action } from "../utils/model"
import Seo from "../components/Seo"

type ActionTemplateProps = {
  data: {
    action: ActionType
  },
  pageContext: { actionId }
}

export default function ActionTemplate(props: ActionTemplateProps) {

  const action = new Action(props.data.action)

  return (
    <Layout>
      <Seo title={"Comment agir dans son entreprise pour le climat? " + action.name}/>
      <ActionDetails action={action} />
    </Layout>
  )
}

export const query = graphql`
query ActionQuery($actionId: String) {
  action: airtable(table: { eq: "Action" }, id: { eq: $actionId }) {
    id
    data {
      Name
      ID
      Stake {
        childMarkdownRemark {
          html
        }
      }
      Details {
        childMarkdownRemark {
          html
        }
      }
      MoreInfo {
        childMarkdownRemark {
          html
        }
      }
      Category
      Type
      Domains {
        id
        data {
          Name
        }
      }
      Target
      Theme {
        id
        data {
            Name
            PictoTheme {
                localFiles {
                    publicURL
                }
            }
        }
      }
    }
  }
}
`