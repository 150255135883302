import React, { useEffect, useState } from "react"
import { useSearchContext, FilterType } from "./SearchContextProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-regular-svg-icons"
import { faArrowLeft, faStar as faStarFull } from "@fortawesome/free-solid-svg-icons"
import { Action } from "../utils/model"
import classnames from "classnames"
import { Link } from 'gatsby'

type ActionNavProps = {
  action: Action
}

const ActionNav = ({action}: ActionNavProps) => {

  const { actions: { selectAction, unselectAction }, filters: { actions } } = useSearchContext();
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(actions.includes(action.externalID))
  }, [actions, action]);

  return (

    <div className="flex flex-row mb-10 text-sm text-primary px-2">
          <Link to="/">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="cursor-pointer text-xs mr-2"
              onClick={() => selected ? unselectAction(action.externalID) : selectAction(action.externalID)}
            />
            Retour à la liste
          </Link>
          <div className="flex-grow text-right cursor-pointer" 
            onClick={() => selected ? unselectAction(action.externalID) : selectAction(action.externalID)}>
            { selected ?  "Supprimer de ma sélection" : "Ajouter à ma sélection" }
            <FontAwesomeIcon
              icon={selected ? faStarFull : faStar}
              className={classnames("cursor-pointer text-xs ml-2", selected ? "text-secondary" : "text-primary" )}
            />
          </div>
        </div>
  )
}

export default ActionNav