import React from "react"
import { Link, navigate } from 'gatsby'
import ActionNav from "../components/ActionNav"
import { Action } from "../utils/model"
import { useSearchContext, filterAction, FilterType } from "../components/SearchContextProvider"
import classnames from "classnames"
import { useSwipeable } from "react-swipeable"

type ActionDetailsProps = {
  action: Action
}

const ActionDetails = ({ action }: ActionDetailsProps) => {

  const { actions: { setFilter },  filters: { filtertype, filtervalue, all, actions } } = useSearchContext();
  let filteredActions = filterAction(all, filtertype, filtervalue);

  const idx = filteredActions.findIndex(a => a.externalID == action.externalID)
  const prevAction = idx > 0 ? filteredActions[idx - 1] : undefined;
  const nextAction = idx < filteredActions.length - 1 ? filteredActions[idx + 1] : undefined;

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => { nextAction && navigate(nextAction?.slug)},
    onSwipedRight: (eventData) => { prevAction && navigate(prevAction?.slug)}
  });

  return (
    <>
      <div className="hidden sm:block fixed left-0 top-1/2">
        <Link to={prevAction?.slug} className="flex flex-col h-full">
          <div
            style={{ width: "40px", height: "75px" }}
            className={classnames(prevAction ? "" : "hidden", "bg-secondary rounded-r-lg text-primary text-2xl font-black pl-2 pt-5 cursor-pointer")}>
            &lt;
          </div>
        </Link>
      </div>
      <div className="hidden sm:block fixed right-0 top-1/2">
        <Link to={nextAction?.slug} className="flex flex-col h-full">
          <div
            style={{ width: "40px", height: "75px" }}
            className={classnames(nextAction ? "" : "hidden", "bg-secondary rounded-l-lg text-primary text-2xl font-black pl-4 pt-5 cursor-pointer")}>
            &gt;
          </div>
        </Link>
      </div>
      <div className="mx-2 sm:mx-auto max-w-xl font-sans pb-10 sm:pt-10" {...handlers}>
        <ActionNav action={action} />
        <div className="flex flex-row mb-2">
          <img className="self-center mr-1" style={{ width: "30px", height: "30px" }} src={action.theme.pictoURL} />
          <span className="self-center text-xs font-extralight text-primary">{action.theme.name}</span>
        </div>
        <h1 className="text-primary text-3xl font-bold">{action.name}</h1>
        <div className="my-2 flex flex-row ">
          {action.domains?.map(domain =>
            <span key={domain.id} className="text-xs py-1 px-2 bg-tag bg-opacity-40 rounded text-primary mr-1 cursor-pointer" 
              onClick={() => { setFilter(FilterType.domain, domain.id); navigate("/") }}>
              {domain.name}
            </span>
          )}
        </div>
        <div className="mt-6 pb-8 mb-4 border-b-2 markdown break-word" dangerouslySetInnerHTML={{ __html: action.stake }}></div>

        <h2 className="text-primary text-xl font-bold">Détails action &amp; impacts</h2>
        <div className="mt-6 markdown break-word" dangerouslySetInnerHTML={{ __html: action.details }}></div>

        <div className="flex flex-row w-full mt-6 text-primary pb-4">
          <div className="w-1/2">
            <div className="uppercase font-thin tracking-widest text-xs mb-3">Type d'action</div>
            <span className="text-xs py-1 px-2 bg-tag2 bg-opacity-40 rounded text-primary mr-1" >{action.type}</span>
          </div>
          <div className="w-1/2">
            <div className="uppercase font-thin tracking-widest text-xs mb-3 flex flex-col">Cible écosystème</div>
            {action.targets?.map(target =>
              <div className="mb-1">
                <span className="text-xs py-1 px-2 bg-tag3 bg-opacity-40 rounded text-primary mr-1" >{target}</span>
              </div>
            )}
          </div>
        </div>

        {action.moreInfo && <div>
          <h2 className="text-primary text-xl font-bold  pt-8 mt-4 border-t-2">Pour aller plus loin</h2>
          <div className="mt-6 w-full markdown" dangerouslySetInnerHTML={{ __html: action.moreInfo }}></div>
        </div>}
      </div>
    </>
  )
}

export default ActionDetails
